import axios from 'axios'
import { VueAxios } from './axios'
import Cookies from 'js-cookie'

class CommonRequest {
  constructor({ baseURL, timeout = 600000, headers = { "Content-Type": "application/json;charset=UTF-8" } }) {
    this.instance = axios.create({
      baseURL,
      timeout,
      headers
    })

    // 异常拦截器
    const errorHandler = error => {
      if (error.response) {
        const data = error.response.data
        this.$notification['error']({
          message: '请求异常',
          description: data.msg || '请求异常'
        });
      }
      return Promise.reject(error)
    }
    // 请求拦截
    this.instance.interceptors.request.use(config => {
      const token = Cookies.get('Authorization')
      if (token) {
        config.headers['Access-Token'] = token
      }
      return config
    })

    // 响应拦截
    this.instance.interceptors.response.use(response => {
      return response.data
    }, errorHandler)
  }
}

const request = new CommonRequest({
  baseURL: process.env.VUE_APP_API_BASE_URL
}).instance

const requestV4 = new CommonRequest({
  baseURL: process.env.VUE_APP_API_NEW_EVER_V4
}).instance

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export {
  installer as VueAxios,
  request,
  requestV4
}